import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleQuestion } from '@fortawesome/pro-light-svg-icons/faFileCircleQuestion';
import { faLaptopMobile } from '@fortawesome/pro-light-svg-icons/faLaptopMobile';
import { faFileChartColumn } from '@fortawesome/pro-light-svg-icons/faFileChartColumn';
import { faUserHelmetSafety } from '@fortawesome/pro-light-svg-icons/faUserHelmetSafety';

const Steps = styled.ol`
	margin: 0;
	padding: 0;
	max-width: 450px;
	margin: 0 auto;
	${p =>
		p.theme.media.medium(css`
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			max-width: none;
			margin: 0;
		`)}
`;

const Step = styled.li`
	margin: 0;
	box-sizing: border-box;
	transition: all 0.3s;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	${p =>
		p.theme.media.medium(css`
			flex: 1;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			padding-left: 40px;
			position: relative;
			&:before {
				position: absolute;
				top: 0;
				left: 20px;
				content: '';
				background-color: ${p => p.theme.colors.blue500};
				width: 12px;
				height: 12px;
				border-radius: 50%;
				transform: translateX(-50%);
			}
			&:after {
				content: '';
				background-image: url("data:image/svg+xml,%3Csvg width='40' height='100%25' preserveAspectRatio='none' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 41.5 259' style='enable-background:new 0 0 41.5 259;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bopacity:0.25;fill:none;stroke:%2382CEF0;stroke-width:3;enable-background:new ;%7D %3C/style%3E%3Cg id='timeline' transform='translate(-885.758 -1804.936)'%3E%3Cpath id='Path_429' class='st0' d='M908.7,1805.9c34.7,131.5-51.5,128.6-5.8,257'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
				height: calc(100% - 18px);
				width: 40px;
				position: absolute;
				left: 0;
				top: 15px;
			}
		`)}
`;

const Inner = styled.div`
	width: 100%;
	${p =>
		p.theme.media.smallOnly(css`
			min-height: 150px;
			display: flex;
			align-items: center;
			padding: 30px 0;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			flex-flow: row wrap;
			align-items: flex-start;
		`)}
`;

const Icon = styled.div`
	display: block;
	${p =>
		p.theme.media.medium(css`
			margin: 0 auto 10px;
			text-align: center;
		`)}
	svg {
		width: auto;
		padding: 5px 10px;
		height: 35px !important;
		color: ${p => p.theme.colors.blue600};
		${p =>
			p.theme.media.medium(css`
				margin-bottom: 10px;
				height: 55px !important;
				padding: 0;
			`)};
	}
`;

const Content = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 0 10px;
	width: 100%;
	${p =>
		p.theme.media.medium(css`
			width: auto;
			border-left: 3px solid ${p => darken(0.15, p.theme.colors.blue200)};
			border-top: 3px solid ${p => darken(0.15, p.theme.colors.blue200)};
			padding: 20px 20px 0;
			border-radius: ${p => `${p.theme.utils.borderRadius} 0 0 0`};
		`)}
`;

const Number = styled.span`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: ${p => p.theme.colors.blue600};
	color: ${p => p.theme.colors.white};
	text-align: center;
	line-height: 30px;
	font-weight: 500;
	position: absolute;
	left: 45px;
	top: 10px;
	${p =>
		p.theme.media.medium(css`
			margin-right: 10px;
			position: static;
			left: auto;
			top: auto;
		`)}
	${p =>
		p.theme.media.XSmallOnly(css`
			left: 51px;
			top: -5px;
		`)}
`;

const Title = styled.div`
	text-align: left;
	${p =>
		p.theme.media.medium(css`
			width: calc(100% - 40px);
		`)}
`;

/**
 * Represents an illustration of a staircase
 * @param {string} title - The title of the component.
 * @param {array} steps - The steps in the staircase
 */
export default function Staircase({
	title,
	steps = [
		{
			icon: <FontAwesomeIcon icon={faFileCircleQuestion} size="5x" />,
			title: 'Analyse',
		},
		{
			icon: <FontAwesomeIcon icon={faLaptopMobile} size="5x" />,
			title: 'Energi-overvåkingssystem',
		},
		{
			icon: <FontAwesomeIcon icon={faFileChartColumn} size="5x" />,
			title: 'Energirapport / -analyse',
		},
		{
			icon: <FontAwesomeIcon icon={faUserHelmetSafety} size="5x" />,
			title: 'Leveranse av energitjenester basert på anbefalte tiltak',
		},
	],
	...props
}) {
	// Run the staircaseStyling function on mount and resize
	useEffect(() => {
		staircaseStyling();

		// Handler for resizing the window
		const handleResize = () => {
			staircaseStyling();
			setTimeout(staircaseStyling, 200);
		};

		// Add event listener for resizing the window
		window.addEventListener('resize', handleResize);

		// Remove event listener for resizing the window
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	if (!steps?.length > 0) return null;

	return (
		<Steps
			aria-label={`${title + '. ' || ''}Totalt ${steps?.length} steg.`}
			className="staircase"
			{...props}>
			{steps.map((step, i) => {
				i++; // Make sure the step number starts at 1

				return (
					<Step key={i} aria-label={`Steg ${i} av ${steps?.length}`}>
						<Inner>
							{step?.icon && (
								<Icon
									aria-label={
										step?.title ||
										'Illustrasjon av steg ' + i
									}>
									{step?.icon}
								</Icon>
							)}
							<Content className="staircase-item">
								<Number>{i}</Number>
								{step?.title && <Title>{step.title}</Title>}
							</Content>
						</Inner>
					</Step>
				);
			})}
		</Steps>
	);
}

/**
 * Function for styling the staircase
 * @returns {void}
 */
function staircaseStyling() {
	// Get all staircase items
	const staircaseItems = document.getElementsByClassName('staircase-item');

	// If there are no items, return
	if (!staircaseItems?.length > 0) return;

	let lastItemHeight = 0;

	// If window width is equal to or greater than 768px
	if (window?.innerWidth >= 768) {
		// Loop through all items
		for (let i = 0; i < staircaseItems.length; i++) {
			if (lastItemHeight !== 0) {
				staircaseItems[i].style.margin = '0 0 ' + lastItemHeight + 'px';
			}
			// Add the height of the current item to the last item height
			lastItemHeight =
				lastItemHeight + (staircaseItems[i].offsetHeight - 3);
		}
	} else {
		// If window width is less than 768px, remove margin
		for (let i = 0; i < staircaseItems.length; i++) {
			staircaseItems[i].style.margin = '0';
		}
	}
}
