import React from 'react';
import styled, { css } from 'styled-components';

import netflix from 'images/fiber/streaming-netflix.png';
import hboMax from 'images/fiber/streaming-hbo-max.png';
import viaplay from 'images/fiber/streaming-viaplay.png';
import vSport from 'images/fiber/streaming-v-sport.png';
import filmFavoritter from 'images/fiber/streaming-filmfavoritter.png';

const List = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: ${p => (p.centered ? 'center' : 'flex-start')};
`;

const ListItem = styled.div`
	padding: 10px;
	min-width: 150px;
	${p =>
		p.theme.media.mediumOnly(css`
			min-width: 100px;
			img {
				width: 100px;
				height: 100px;
			}
		`)}

	${p =>
		p.theme.media.XSmallOnly(css`
			min-width: auto;
			padding: 5px;
			img {
				width: 75px;
				height: 75px;
			}
		`)}
`;

/**
 * Represents an array of the available streaming services
 */
const services = [
	{
		title: 'Netflix',
		image: {
			src: netflix,
			altText: 'Netflix logo',
		},
	},
	{
		title: 'HBO Max',
		image: {
			src: hboMax,
			altText: 'HBO Max logo',
		},
	},
	{
		title: 'Viaplay',
		image: {
			src: viaplay,
			altText: 'Viaplay logo',
		},
	},
	{
		title: 'V Sport',
		image: {
			src: vSport,
			altText: 'V Sport logo',
		},
	},
	{
		title: 'Filmfavoritter',
		image: {
			src: filmFavoritter,
			altText: 'Filmfavoritter logo',
		},
	},
];

/**
 * Represents a grid of streaming services logos
 * @param {Object} props - The component properties
 * @returns {JSX.Element} - The component
 */
export default function StreamingServices({ ...props }) {
	if (!services?.length > 0) return;

	return (
		<List role="list" {...props}>
			{services.map((service, i) => (
				<ListItem role="listitem" aria-label={service?.title} key={i}>
					<img
						src={service?.image?.src}
						width="130"
						height="130"
						alt={service?.image?.altText || service?.title}
					/>
				</ListItem>
			))}
		</List>
	);
}
