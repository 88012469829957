import React from 'react';
import styled, { css } from 'styled-components';
import { renderToString } from 'react-dom/server';

import Link from 'components/Link';
import SanitizeHtml from 'libs/SanitizeHtml';
import Heading from 'libs/heading';
import Box from 'components/forms/Box';

//#region
const Wrapper = styled.div`
	margin-top: ${p => (p.hidetitle && '0') || '45px'};
`;

const ItemInner = styled.div`
	display: inline-block;
	max-width: calc(50% - 45px);
	text-align: left;
	width: 100%;
	${p =>
		p.theme.media.smallOnly(css`
			max-width: 100%;
			margin-bottom: 20px;
		`)}
	h3 {
		margin-bottom: 20px;
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: 10px;
			`)}
	}
`;

const Text = styled(Box)`
	text-align: left;
	box-sizing: border-box;
	${p =>
		p.theme.media.smallOnly(css`
			margin-left: 25px;
		`)}
`;

const Item = styled.div`
	position: relative;
	text-align: right;
	${p =>
		p.theme.media.smallOnly(css`
			margin: 5px 0 5px 20px;
		`)}
	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		content: ' ';
		background-color: ${p => p.theme.colors.blue500};
		width: 12px;
		height: 12px;
		border-radius: 50%;
		transform: translateX(-50%);
		${p =>
			p.theme.media.smallOnly(css`
				margin-left: 0;
				left: -5px;
			`)}
	}
	&:nth-child(odd) {
		text-align: left;
		${ItemInner} {
			text-align: right;
			${p =>
				p.theme.media.smallOnly(css`
					text-align: left;
				`)}
		}
	}
	&:last-child {
		svg {
			display: none;
		}
	}
	${p =>
		p.theme.media.smallOnly(css`
			text-align: left;
		`)}
	p {
		margin: 0;
	}
`;

const Svg = styled.svg`
	position: absolute;
	top: 15px;
	left: 50%;
	height: calc(100% - 18px) !important;
	transform: translateX(-50%);
	${p =>
		p.theme.media.smallOnly(css`
			left: -10px;
			transform: translateX(-15px);
		`)}
	path {
		opacity: 0.25;
		fill: none;
		stroke: ${p => p.theme.colors.blue400};
		stroke-width: 3;
		enable-background: new;
	}
`;
//#endregion

/**
 * Represents a timeline-illustration
 * @param {string} title - The title of the component.
 * @param {array} steps - The steps in the timeline
 * @param {...object} props - Rest of the properties
 */
export default function Timeline({
	title,
	steps = [
		{
			title: 'I DAG...',
			text: 'registrerer vi avtalen din, og innhenter måleravlesning for strømmåleren din. Dette trenger du ikke tenke på, vi fikser alt. Du kan allerede nå laste ned appen Mitt NTE og få full oversikt over strømprisen.',
		},
		{
			title: 'Etter TO UKER...',
			text: `Vil du motta vårt velkomstbrev på e-post. Her finner du nyttig informasjon om blant annet kundeservice, ${renderToString(
				<Link
					to="/om-nte/gir-mer/"
					title="Gå til informasjonssiden om fordelsprogrammet Gir Mer">
					fordelsprogrammet Gir Mer
				</Link>
			)} og ${renderToString(
				<Link
					to="/app"
					title="Gå til informasjonssiden om appen Mitt NTE">
					appen Mitt NTE
				</Link>
			)}. Fordelsprogrammet gir deg tilgang til en rekke tilbud, fordeler og konkurranser fra lokale og nasjonale samarbeidspartnere.`,
		},
		{
			title: 'Etter 16 DAGER',
			text: `aktiveres strømavtalen din. Da vil du motta en ny e-post med oppsummering av avtalen din. Har du enda ikke lastet ned appen vår Mitt NTE? ${renderToString(
				<Link
					to="/app"
					title="Gå til informasjonssiden om appen Mitt NTE">
					I appen Mitt NTE
				</Link>
			)} har du nå full oversikt over din strømavtale.`,
		},
		{
			title: 'EN GANG I MÅNEDEN…',
			text: `vil du få faktura fra oss. Alle dine fakturaer er samlet i ${renderToString(
				<Link
					to="/app"
					title="Gå til informasjonssiden om appen Mitt NTE">
					appen Mitt NTE
				</Link>
			)}. På hver faktura kan du enkelt se hvordan beløpet fordeler seg mellom nettleie, spotpris og andre avtalekostnader. Ønsker du eFaktura eller AvtaleGiro kan du enkelt ordne det i din nettbank.`,
		},
	],
	...props
}) {
	if (!steps?.length > 0) return null;

	return (
		<Wrapper aria-label={title || ''} className="timeline" {...props}>
			{steps.map((step, i) => {
				return (
					<Item key={i}>
						<ItemInner>
							<Text>
								{step?.title && (
									<Heading level="h3">{step.title}</Heading>
								)}
								<SanitizeHtml html={step?.text} />
							</Text>

							<Svg
								width="40"
								height="100%"
								preserveAspectRatio="none"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								viewBox="0 0 41.5 259"
								style={{
									enableBackground: 'new 0 0 41.5 259',
								}}>
								<g transform="translate(-885.758 -1804.936)">
									<path d="M908.7,1805.9c34.7,131.5-51.5,128.6-5.8,257" />
								</g>
							</Svg>
						</ItemInner>
					</Item>
				);
			})}
		</Wrapper>
	);
}
